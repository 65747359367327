//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { sysTimersPage, sysTimersDelete, sysTimersStart, sysTimersStop } from '@/api/modular/system/timersManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  name: 'PosIndex',
  components: {
    XCard,
    STable,
    Ellipsis,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '任務名稱',
          dataIndex: 'timerName'
        },
        {
          title: '任務class類名',
          dataIndex: 'actionClass',
          scopedSlots: { customRender: 'actionClass' }
        },
        {
          title: '定時任務表達式',
          dataIndex: 'cron'
        },
        {
          title: '備註',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '狀態',
          dataIndex: 'jobStatus',
          scopedSlots: { customRender: 'jobStatus' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysTimersPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      jobStatusDictTypeDropDown: []
    }
  },
  created () {
    this.sysDictTypeDropDown()// 註釋掉
    if (this.hasPerm('sysTimers:edit') || this.hasPerm('sysTimers:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.jobStatusDictTypeDropDown = this.$options.filters['dictData']('run_status')
    },
    // jobStatusFilter (jobStatus) {
    //   // eslint-disable-next-line eqeqeq
    //   const values = this.jobStatusDictTypeDropDown.filter(item => item.code == jobStatus)
    //   if (values.length > 0) {
    //     return values[0].value
    //   }
    // },
    /**
     * 啟動停止
     */
    editjobStatusStatus (code, record) {
      // eslint-disable-next-line eqeqeq
      if (code == 1) {
        sysTimersStop({ id: record.id }).then(res => {
          if (res.success) {
            this.$message.success('停止成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('停止失敗：' + res.message)
          }
        })
      // eslint-disable-next-line eqeqeq
      } else if (code == 2) {
        sysTimersStart({ id: record.id }).then(res => {
          if (res.success) {
            this.$message.success('啟動成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('啟動失敗：' + res.message)
          }
        })
      }
    },
    sysTimersDelete (record) {
      sysTimersDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    }
  }
}
